import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import EHawkInfo from "./EHawkInfo";
import FailLead from "./FailLead";
import InfutorScores from "./InfutorScores";
import LeadDetailsPage from "./leadpage/LeadDetailsPage";
import LeadFieldsPage from "./leadpage/LeadFieldsPage";
import LeadIOLog from "./leadpage/LeadIOLog";
import LeadPostLog from "./leadpage/LeadPostLog";
import LeadScrubbingLog from "./leadpage/LeadScrubbingLog";
import { Link } from "react-router-dom";
import Log from "./leadpage/Log";
import ManualPost from "./ManualPost";
import ManualProcess from "./leadpage/ManualProcess";
import MatchedNoBuyer from "./MatchedNoBuyer";
import MetaData from "./leadpage/MetaData";
import RevenueUploadLog from "./leadpage/RevenueUploadLog";
import Tokens from "./leadpage/Tokens";
import TrafficLog from "./leadpage/TrafficLog";
import _ from "lodash";
import classnames from "classnames";
import Delete from "./leadpage/Delete";
import SystemLog from "../../components/SystemLog";
import {toastr} from "react-redux-toastr";
import LeadFilterLog from "./leadpage/LeadFilterLog";
import OneToOne from "./leadpage/OneToOne";
import usePermissions from "../../hooks/usePermissions";

const Lead = (props) => {
  const [activeTab, setActiveTab] = useState("summary");
  const [update, setUpdate] = useState(true);
  const [leadNotExist, setLeadNotExist] = useState(false)

  const [lead, setLead] = useState(null);
  const [ping, setPing] = useState(null);
  const [fields, setFields] = useState(null);
  const [postlog, setPostlog] = useState(null);
  const [pinglog, setPinglog] = useState(null);
  const [filterlog, setFilterlog] = useState(null);
  const [ioLogPing, setIoLogPing] = useState(null);
  const [ioLogPost, setIoLogPost] = useState(null);
  const [ioLogPostMatch, setIoLogPostMatch] = useState(null);
  const [ioLogPostMatchSecond, setIoLogPostMatchSecond] = useState(null);
  const [editConfig, setEditConfig] = useState(null);
  const [trafficLog, setTrafficLog] = useState(null);
  const [scrubLog, setScrubLog] = useState([]);
  const [oneToOne, setOneToOne] = useState(null);

  const canDelete = usePermissions('lead_deletelead');
  const canDeleteFromBuyer = usePermissions('lead_removefrombuyer');
  const canManualMatch = usePermissions('lead_manualposting');

  useEffect(() => {
    let id;
    if (props.id) {
      id = props.id;
    } else {
      id = props?.match?.params?.id;
    }
    API.get("/admin/lead/" + id).then((response) => {
      setLead(response.data.data);
    }).catch((e) => {
        if (e.response.status === 400) {
            setLeadNotExist(true)
        }
    });
    API.get("/admin/lead/fields/" + id).then((response) => {
      setFields(response.data.data);
    });
    API.get("/admin/lead/log/" + id).then((response) => {
      if (response.data.data.ping) {
        setIoLogPing(response.data.data.ping);
      }
      if (response.data.data.post) {
        setIoLogPost(response.data.data.post);
      }
      if (response.data.data.postMatch) {
        setIoLogPostMatch(response.data.data.postMatch);
      }
      if (response.data.data.postMatchSecond) {
        setIoLogPostMatchSecond(response.data.data.postMatchSecond);
      }
    });
    API.get("/admin/lead/postinglog/" + id).then((response) => {
      setPostlog(response.data.data.post);
      setPinglog(response.data.data.ping);
      setFilterlog(response.data.data.filterLog);
    });

    API.get("/admin/leadedit/" + id).then((response) => {
      setEditConfig(response.data.data);
    });

    API.get("/admin/lead/trafficlog/" + id).then((response) => {
      if (response.data.data && !_.isEmpty(response.data.data)) {
        setTrafficLog(response.data.data);
      }
    });

    API.get("/admin/lead/scrubbing/" + id).then((response) => {
      setScrubLog(response.data.data);
    });

    API.get("/admin/lead/onetoone/" + id).then((response) => {
      setOneToOne(response.data.data);
    });
  }, [props?.match?.params?.id, props.id, update]);

  useEffect(() => {
    if (lead && lead.pingid) {
      API.get("/admin/ping/" + lead.pingid).then((response) => {
        setPing(response.data.data);
      });
    }
  }, [lead]);

  const runUpdate = () => {
    setUpdate(!update);
    props.setNeedReloadList(true);
  };

  const findScrubberInfo = (section) => {
    return scrubLog.find((value) => value.scrubber === section);
  };

  const handleSave = (updatedData) => {
    API.put("/admin/lead/trafficlog/" + props.id, updatedData)
    .then((response) => {
        toastr.success('Success', 'Tracking parameter was successfully saved')
    })
  };

  const canFailLead = usePermissions('lead_faillead');
  const canRematchLead = usePermissions('lead_rematchlead');
  const canMatchNoBuyer = usePermissions('lead_setmatchnobuyer');

  return (
    <Container fluid className="p-0">
      {leadNotExist ?
          <h4>Lead not found</h4>
          :
          <>
        {!props.id && (
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/lead/report/details">Leads details report</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {props?.match?.params?.id ?? props.id}
            </BreadcrumbItem>
          </Breadcrumb>
        )}
        <div className={"tab default"}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "summary" })}
                onClick={() => {
                  setActiveTab("summary");
                }}
              >
                Summary
              </NavLink>
            </NavItem>
              <NavItem>
                  <NavLink
                      className={classnames({ active: activeTab === "onetoone" })}
                      onClick={() => {
                          setActiveTab("onetoone");
                      }}
                  >
                      One To One
                  </NavLink>
              </NavItem>
            {(lead?.metaData || lead?.tcpa) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "metaData" })}
                  onClick={() => {
                    setActiveTab("metaData");
                  }}
                >
                  Meta Data
                </NavLink>
              </NavItem>
            )}
            {lead?.cert && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "cert" })}
                  onClick={() => {
                    setActiveTab("cert");
                  }}
                >
                  Certificates
                </NavLink>
              </NavItem>
            )}
            {lead && lead?.revenueUpload?.length > 0 && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "revenueUpload",
                  })}
                  onClick={() => {
                    setActiveTab("revenueUpload");
                  }}
                >
                  Revenue upload{" "}
                  <Badge color="primary">{lead.revenueUpload.length}</Badge>
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "details" })}
                onClick={() => {
                  setActiveTab("details");
                }}
              >
                Lender post log{" "}
                {postlog && postlog.length > 0 && (
                  <Badge color="success">{postlog.length}</Badge>
                )}{" "}
                {pinglog && pinglog.length > 0 && (
                  <Badge color="primary">{pinglog.length}</Badge>
                )}
                  {filterlog && filterlog.length > 0 && (
                      <Badge color="warning">{filterlog.length}</Badge>
                  )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "postlog" })}
                onClick={() => {
                  setActiveTab("postlog");
                }}
              >
                Request/Response
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "systemLogs" })}
                onClick={() => {
                  setActiveTab("systemLogs");
                }}
              >
                System Logs
              </NavLink>
            </NavItem>
            {canManualMatch &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "manual" })}
                onClick={() => {
                  setActiveTab("manual");
                }}
              >
                Manual process
              </NavLink>
            </NavItem>
            }
            {findScrubberInfo("eHawk") && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "eHawk" })}
                  onClick={() => {
                    setActiveTab("eHawk");
                  }}
                >
                  EHawk Response
                </NavLink>
              </NavItem>
            )}
            {findScrubberInfo("Infutor") && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "infutor" })}
                  onClick={() => {
                    setActiveTab("infutor");
                  }}
                >
                  Infutor Scores
                </NavLink>
              </NavItem>
            )}

            {trafficLog && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "traffictracking",
                  })}
                  onClick={() => {
                    setActiveTab("traffictracking");
                  }}
                >
                  Tracking parameters
                </NavLink>
              </NavItem>
            )}

            {(canDelete || canDeleteFromBuyer) &&
            <NavItem>
              <NavLink
                  className={classnames({ active: activeTab === "delete" })}
                  onClick={() => {
                    setActiveTab("delete");
                  }}
              >
                Delete tool
              </NavLink>
            </NavItem>
            }
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === "log" })}
                    onClick={() => {
                        setActiveTab("log");
                    }}
                >
                    Log
                </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="summary">
              <div className="row">
                <div className="col-md-6 col-sm-12 mt-md-1">
                  {lead && <LeadDetailsPage lead={lead} ping={ping} />}
                </div>
                <div className="col-md-6 col-sm-12 mt-md-1 mt-sm-5">
                  {fields && lead && (
                    <LeadFieldsPage
                      fields={fields}
                      leadId={lead?.id}
                      editConfig={editConfig}
                    />
                  )}
                </div>
              </div>
              {lead && (
                <>
                  <div className="row justify-content-center">
                    {canFailLead &&
                    <div className="col-auto m-2">
                      <FailLead lead={lead} runUpdate={runUpdate} />
                    </div>
                    }
                    {canRematchLead &&
                    <div className="col-auto m-2">
                      <ManualPost lead={lead} runUpdate={runUpdate} />
                    </div>
                    }
                    {canMatchNoBuyer &&
                    <div className="col-auto m-2">
                      <MatchedNoBuyer lead={lead} runUpdate={runUpdate} />
                    </div>
                    }
                  </div>
                </>
              )}
              <LeadScrubbingLog log={scrubLog} />
            </TabPane>
            <TabPane tabId="onetoone">
              {oneToOne &&
                <OneToOne data={oneToOne} />
              }
            </TabPane>
            {(lead?.metaData || lead?.tcpa) && (
              <TabPane tabId="metaData">
                <MetaData metaData={lead?.metaData} tcpa={lead?.tcpa} />
              </TabPane>
            )}
            {lead?.cert && (
              <TabPane tabId="cert">
                <Tokens data={lead.cert} />
              </TabPane>
            )}
            <TabPane tabId="details">
              {pinglog && pinglog.length > 0 && (
                <>
                  <h4>Ping</h4>
                  <LeadPostLog postlog={pinglog} isPing={true} />
                </>
              )}
              {postlog && postlog.length > 0 && (
                <>
                  {pinglog && pinglog.length > 0 && (
                    <>
                      <h4>Post</h4>
                    </>
                  )}
                  <LeadPostLog postlog={postlog} isPing={false} />
                </>
              )}
              {filterlog && filterlog.length > 0 && (
                  <>
                    <h4>Filtered on post</h4>
                    <LeadFilterLog filterlog={filterlog}/>
                  </>
              )}
            </TabPane>
            {lead && lead?.revenueUpload?.length > 0 && (
              <TabPane tabId="revenueUpload">
                <RevenueUploadLog log={lead?.revenueUpload} />
              </TabPane>
            )}
            <TabPane tabId="postlog">
              {ioLogPing && (
                <>
                  <h4>Ping</h4>
                  <LeadIOLog iolog={ioLogPing} />
                </>
              )}
              {ioLogPost && (
                <>
                  {ioLogPing && (
                    <>
                      <hr />
                      <h4>Post</h4>
                    </>
                  )}
                  {ioLogPostMatch && (
                      <>
                        <hr />
                        <h4>Pre-match</h4>
                      </>
                  )}
                  <LeadIOLog iolog={ioLogPost} />
                </>
              )}
              {ioLogPostMatch &&
                  <div className="mt-3">
                    <h4>Post match</h4>
                    <LeadIOLog iolog={ioLogPostMatch}/>
                  </div>
              }
              {ioLogPostMatchSecond &&
                  <div className="mt-3">
                    <h4>Post match, second</h4>
                    <LeadIOLog iolog={ioLogPostMatchSecond} />
                  </div>
              }
            </TabPane>
            <TabPane tabId="systemLogs">
              <Log leadId={lead?.id} update={update} />
            </TabPane>
            {canManualMatch &&
            <TabPane tabId="manual">
              <ManualProcess id={lead?.id} runUpdate={runUpdate} />
            </TabPane>
            }
            <TabPane tabId="eHawk">
              <EHawkInfo eHawkScrubber={findScrubberInfo("eHawk")} />
            </TabPane>
            <TabPane tabId="infutor">
              <InfutorScores infutorScrubber={findScrubberInfo("Infutor")} />
            </TabPane>
            {trafficLog && (
              <TabPane tabId="traffictracking">
                <TrafficLog data={trafficLog} onSave={handleSave}/>
              </TabPane>
            )}
            {(canDelete || canDeleteFromBuyer) &&
              <TabPane tabId="delete">
                <Delete id={lead?.id} postLog={postlog} runUpdate={runUpdate}/>
              </TabPane>
            }
            <TabPane tabId="log">
              {lead &&
                <SystemLog lead={lead?.id}/>
              }
            </TabPane>
          </TabContent>
        </div>
        </>
        }
    </Container>
  );
};

export default Lead;
